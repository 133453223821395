import maputilities from '@smarttransit/map-utilities'
import _orderBy from 'lodash.orderby'
import moment from 'moment-timezone'

import {
  startGeneratePermutations,
  getStRoute,
  stopGeneratePermutations,
  getPermutationsJob, getRouteFaresByRouteId
} from '@/services/st-routes-service'

import { addAlert } from '@/utilities/helpers'
import { findCountryCurrencies } from '@/services/country-service'
import { formatCurrency, formatDate, formatTime } from '@smarttransit/common/src/helpers'
import { createRouteFareAndAssociateToRoute, updateRouteFareByDateUpdated } from '@/services/route-fare-service'

export default {
  props: {
    signedInUser: Object,
    routeId: String,
    hasUserType: Function,
    forceRootViewRefresh: Function
  },
  data () {
    return {
      name: 'routes-route-fare-permutations',
      currentRoute: null,
      apiInProgress: false,
      currentPermutationsError: null,
      permutationsJobError: null,
      farePermutationsContainerHeight: 0,
      routeFares: [],
      searchKeywords: '',
      countryCurrency: null,
      permutationsJob: null,
      enableVirtualList: false,
      permutationsSuccess: false,
      csvDataFilename: null
    }
  },
  mounted () {
    // this.$nextTick(() => {
    //   this.onResize()
    //   this.enableVirtualList = true
    // })

    this.agencies = this.$store.getters.getAgencies
    this.unwatchRouteControlBar = this.$watch(() => this.$refs.routeControlBar, this.onResize)

    this.getRoute().then(() => {
      this.getRouteFares()
      this.initPermutationsJobMonitor()
    }).finally(() => {
      this.onResize()
      this.enableVirtualList = true
    })
  },
  watch: {
    searchKeywords () {
      clearTimeout(this.handleSearchRouteFares)

      this.handleSearchRouteFares = setTimeout(() => {
        this.searchRouteFares()
      }, 500)
    }
  },
  computed: {
    // hasAdminPermissions () {
    //   return this.$props.hasUserType && this.$props.hasUserType('admin')
    // },
    isPermutationsPending () {
      return !this.permutationsJob || this.permutationsJob?.jobStatus === 'pending'
    },
    isPermutationsCancelled () {
      return this.permutationsJob?.jobStatus === 'cancelled'
    },
    isPermutationsRunning () {
      return this.permutationsJob?.jobStatus === 'running'
    },
    hasRouteIdListChanged () {
      if (this.currentRoute?.routeIdList !== this.currentRoute?.metadata?.routeIdList) {
        if (this.currentRoute) {
          if (this.currentRoute.metadata?.routeIdList) {
            return 'Route path has changed, recommend generating new route fare permutations'
          }

          return 'Generate route fare permutations for this route'
        }
      }

      return null
    },
    permutationsJobStatus () {
      return this.permutationsJob ? `Running permutations job: ${this.permutationsJob.dateLabel}<br />${this.permutationsJob.metadata?.currentIndex || 0}/${this.permutationsJob.metadata?.totalBusStops || 0} stops processed` : ''
    }
  },
  methods: {
    startPermutationsJob () {
      if (!this.currentRoute?.id) {
        return alert('No route found to start job from')
      }

      if (this.isPermutationsRunning) {
        return alert('Permutations job is running, please cancel it first')
      }

      if (confirm('Are you sure you want to generate new route fare permutations? This may take 10-20 minutes.')) {
        startGeneratePermutations(this.currentRoute.id)
          .then((job) => {
            this.permutationsJob = this.marshalPermutationsJob(job)
          }).catch((err) => {
            addAlert({ message: err, type: 'error' })
          }).finally(() => {
            this.initPermutationsJobMonitor()
          })
      }
    },
    stopPermutationsJob () {
      if (!this.currentRoute?.id) {
        return alert('No route found to end job')
      }

      if (!this.isPermutationsRunning) {
        return alert('Permutations job is not running')
      }

      if (confirm('Are you sure you want to cancel the route fare permutations job?')) {
        stopGeneratePermutations(this.currentRoute.id)
          .then((job) => {
            this.permutationsJob = this.marshalPermutationsJob(job)
            clearTimeout(this.permutationsJobMonitorHandle)
          }).catch((err) => {
            addAlert({ message: err, type: 'error' })
          })
      }
    },
    visibleBulkEditIndexes (startIndex, endIndex) {
      this.visibleBulkEditIndexObj = { startIndex, endIndex }

      if ((startIndex || startIndex === 0) && endIndex) {
        this.clonedRouteFares = this.$data.routeFares.slice(startIndex, endIndex).map((o) => ({ ...o }))
      } else {
        this.clonedRouteFares = []
      }
    },
    marshalPermutationsJob (job) {
      return job && {
        ...job,
        dateLabel: job.dateNextRun ? `${formatDate(job.dateNextRun)} @ ${formatTime(job.dateNextRun)}` : '(unknown)'
      }
    },
    currencyFieldChildProps (item, index) {
      return {
        'full-width': false,
        class: 'text-field--compact',
        autofocus: index === 0,
        rules: [v => Boolean(v && v.length) || 'Route fare required'],
        placeholder: item.routeFareObj.potentialFare ? `Potentially ${formatCurrency(item.routeFareObj.potentialFare)}` : 'Enter fare',
        'hide-details': true,
        tabindex: index + 1,
        step: '0.01',
        min: 0
      }
    },
    async getRoute () {
      this.apiInProgress = true

      try {
        this.currentRoute = await getStRoute({ id: this.$props.routeId })
        const countryCurrencyId = this.agencies.find((o) => (o.value === this.currentRoute.agencyId))?.metadata?.countryId

        if (countryCurrencyId && this.countryCurrency?.countryId !== countryCurrencyId) {
          this.countryCurrency = await findCountryCurrencies({ where: { countryId: countryCurrencyId } })
          this.countryCurrency = this.countryCurrency?.length ? this.countryCurrency[0] : null
        }
      } finally {
        this.apiInProgress = false
      }
    },
    initSuccessDisplay () {
      this.permutationsSuccess = true

      this.permutationsSuccessHandle = setTimeout(() => {
        this.permutationsSuccess = false
      }, 10000)
    },
    async initPermutationsJobMonitor () {
      clearTimeout(this.permutationsJobMonitorHandle)

      if (!this.currentRoute) {
        throw new Error('No route found')
      }

      this.permutationsJobMonitorHandle = setTimeout(async () => {
        try {
          this.permutationsJobError = null
          const previousJobStatus = this.permutationsJob?.jobStatus
          const job = await getPermutationsJob(this.currentRoute.id)
          this.permutationsJob = this.marshalPermutationsJob(job)

          if (this.permutationsJob?.metadata?.lastError) {
            throw new Error(this.permutationsJob.metadata.lastError)
          }

          if (this.permutationsJob?.jobStatus === 'pending' && previousJobStatus === 'running') {
            this.initSuccessDisplay()
            await this.getRoute()
            this.getRouteFares()
          } else {
            this.initPermutationsJobMonitor()
          }
        } catch (err) {
          this.permutationsJobError = err?.error ? err.error.message : (err.message ? err.message : err)
        }
      }, 5000)
    },
    async getRouteFares () {
      this.apiInProgress = true

      try {
        let stRouteRouteFares = await getRouteFaresByRouteId(this.currentRoute.id)
        stRouteRouteFares = _orderBy(stRouteRouteFares, ['orderIndex'], ['asc'])
        this.routeFares = stRouteRouteFares.map((o) => (this.marshalStRouteRouteFare(o)))
        this.originalRouteFares = [].concat(this.routeFares)
      } finally {
        this.apiInProgress = false
      }
    },
    marshalStRouteRouteFare (stRouteRouteFare) {
      const routeFare = stRouteRouteFare.routeFare || stRouteRouteFare.metadata
      const permutationLabel = stRouteRouteFare.routeFare ? stRouteRouteFare.routeFare?.metadata?.permutationLabel || stRouteRouteFare.routeFare?.routeLabel : stRouteRouteFare.metadata?.permutationLabel || stRouteRouteFare.metadata?.routeLabel
      const permutationLabels = permutationLabel.split(maputilities.ROUTE_LABEL_CONNECTOR).map((o) => o.trim())
      const labels = routeFare.routeLabel?.split(maputilities.ROUTE_LABEL_CONNECTOR).map((o) => o.trim()) || []

      stRouteRouteFare.routeFareObj = {
        ...routeFare,
        fareDollar: routeFare.fare || 0,
        routeLabelFrom: labels?.[0] || '',
        routeLabelTo: labels?.length > 1 ? labels[1] : '',
        permutationLabelFrom: permutationLabels.length ? permutationLabels[0] : '',
        permutationLabelTo: permutationLabels.length > 1 ? permutationLabels[1] : '',
        potentialFare: routeFare.potentialFare || routeFare.metadata?.potentialFare,
        dateUpdatedLabel: routeFare.dateUpdated ? `${formatTime(routeFare.dateUpdated)}, ${formatDate(routeFare.dateUpdated)}` : '(new)'
      }

      return stRouteRouteFare
    },
    searchRouteFares () {
      if (this.searchKeywords) {
        this.routeFares = this.originalRouteFares.filter((o) => {
          return o.routeFareObj.routeLabelFrom.toLowerCase().indexOf(this.searchKeywords.toLowerCase()) > -1 ||
            o.routeFareObj.routeLabelTo.toLowerCase().indexOf(this.searchKeywords.toLowerCase()) > -1
        })
      } else {
        this.routeFares = [].concat(this.originalRouteFares)
      }
    },
    isRouteFareEditOccurred (routeFare) {
      // const clonedRouteFare = this.clonedRouteFares.find((o) => o.id === routeFare.id)
      const fare = routeFare.routeFareObj.fareDollar
      return fare && fare !== routeFare.routeFareObj.fare
    },
    async routeFarePossiblyEdited (routeFare) {
      // const clonedRouteFare = this.clonedRouteFares.find((o) => o.id === routeFare.id)

      if (this.isRouteFareEditOccurred(routeFare)) {
        // change has been made, so perform update
        try {
          // this.idState.currentBulkEditItem = routeFare
          // this.idState.currentBulkEditItem.apiState = 'loading'
          routeFare.apiState = 'loading'
          let result = null

          if (routeFare.routeFareObj.id) {
            result = await updateRouteFareByDateUpdated({
              id: routeFare.routeFareObj.id,
              fare: routeFare.routeFareObj.fareDollar,
              dateUpdated: routeFare.routeFareObj.dateUpdated || new Date()
            })

            result = this.marshalStRouteRouteFare({ ...routeFare, routeFare: result })
          } else {
            result = await createRouteFareAndAssociateToRoute({
              stRouteId: routeFare.stRouteId,
              stRouteRouteFareId: routeFare.id,
              routeLabel: routeFare.routeFareObj.routeLabel,
              fare: routeFare.routeFareObj.fareDollar,
              agencyId: this.currentRoute.agencyId,
              routeIdList: routeFare.routeFareObj.routeIdList,
              routeLine: routeFare.routeFareObj.routeGeometry,
              potentialFare: routeFare.routeFareObj.potentialFare,
              potentialFareId: routeFare.routeFareObj.potentialFareId
            })

            result = this.marshalStRouteRouteFare(result)
          }

          routeFare.id = result.id
          routeFare.orderIndex = result.orderIndex
          routeFare.routeFareId = result.routeFareId
          routeFare.dateCreated = result.dateCreated
          routeFare.metadata = result.metadata
          routeFare.routeFareObj = result.routeFareObj
          routeFare.apiState = 'success'
          const routeFareIndex = this.routeFares.findIndex((o) => o.id === routeFare.id)

          if (this.routeFares[routeFareIndex]) {
            setTimeout(() => {
              routeFare.apiState = null
              this.routeFares[routeFareIndex] = { ...routeFare, apiState: null }
              // this.routeFares = [].concat(this.routeFares)
            }, 5000)
          }

          const originalRouteFareIndex = this.originalRouteFares.findIndex((o) => o.id === routeFare.id)

          if (this.originalRouteFares[originalRouteFareIndex]) {
            this.originalRouteFares[originalRouteFareIndex] = { ...routeFare, apiState: null }
          }

          const clonedIndex = this.clonedRouteFares.findIndex((o) => o.id === routeFare.id)

          if (this.clonedRouteFares[clonedIndex]) {
            this.clonedRouteFares[clonedIndex] = { ...routeFare, apiState: null }
            this.clonedRouteFares = [].concat(this.clonedRouteFares)
          }
        } catch (error) {
          routeFare.apiState = 'error'
          addAlert({ message: error, type: 'error' })
        }
      }
    },
    onResize () {
      const breadcrumbsHeight = 57
      const routeControlBarHeight = this.$refs?.routeControlBar?.clientHeight || 0
      const routeHeaderHeight = this.$refs?.routeHeader?.clientHeight || 0
      const bulkEditorFooterHeight = this.$refs?.bulkEditorFooter?.clientHeight || 0

      const offsetHeight = routeControlBarHeight + routeHeaderHeight + this.$store.getters.appToolbarHeight + this.$store.getters.appFooterHeight + breadcrumbsHeight + bulkEditorFooterHeight + 40
      this.farePermutationsContainerHeight = window.innerHeight - offsetHeight
    },
    refreshView () {
      this.$router.go()
    },
    loadParentView () {
      this.$router.push({ name: 'routes-route', params: { routeId: this.$props.routeId } }, () => (this.$props.forceRootViewRefresh()))
    },
    onDownloadButtonClicked () {
      return new Promise((resolve, reject) => {
        if (confirm('Confirm downloading all ' + this.routeFares.length + ' permutations')) {
          return resolve(true)
        }

        resolve(false)
      })
    },
    async onDownloadingRoutes () {
      const regexp = new RegExp(`[${maputilities.ROUTE_LABEL_CONNECTOR}]+`, 'g')
      this.csvDataFilename = `${this.currentRoute.label.replace(regexp, '-').replace(/[^-A-Za-z0-9]+/g, '')}-${moment().format('Y-MM-DD-HH:mm:ss')}.csv`
      const csvData = []
      const countResult = this.routeFares.length

      if (countResult) {
        csvData.push(`From,To,Date Updated,${this.countryCurrency?.currencySymbol ? '(' + this.countryCurrency.currencyCode + ') ' : ''}Fare`)

        for (const routeFare of this.routeFares) {
          csvData.push(`"${routeFare.routeFareObj.routeLabelFrom}","${routeFare.routeFareObj.routeLabelTo}","${routeFare.routeFareObj.dateUpdatedLabel}","${formatCurrency(routeFare.routeFareObj.fareDollar, null, '0.00')}"`)
        }

        return csvData.join('\n')
      } else {
        throw new Error('No permutations data found to download')
      }
    }
  },
  beforeDestroy () {
    clearTimeout(this.handleSearchRouteFares)
    clearTimeout(this.permutationsSuccessHandle)
    clearTimeout(this.permutationsJobMonitorHandle)
    this.unwatchRouteControlBar && this.unwatchRouteControlBar()
  }
}
